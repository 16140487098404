<template>
  <el-drawer v-model="drawer" :close-on-click-modal="false" size="1050px">
    <template #header>
      <div class="header_top">
        <div>退款</div>
        <div>
          <el-button type="primary" @click="save" size="small" v-if="!form.id">保存</el-button>
        </div>
      </div>
    </template>
    <el-form :model="form" label-width="100px" :rules="rules" size="small" :inline="true" ref="ruleFormRef">
      <el-form-item label="客户名称：" prop="customerId">
        <select-common placeholder="请选择客户" v-model:modelValue="form.customerId" :options="customerOptions"
          class="w390 ml-mr-0" labelKey="customerName" valueKey="id" @selectChange="changeCustomer" />
      </el-form-item>
      <el-form-item label="退款日期：" prop="refundDate">
        <el-date-picker style="width: 145px" value-format="YYYY-MM-DD" type="date" placeholder="请选择开始日期"
          v-model="form.refundDate" :clearable="false">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="单据编码：" prop="documentCode">
        <el-input v-model="form.documentCode" placeholder="请输入单据编码"></el-input>
      </el-form-item>
      <el-form-item label="结算方式：" prop="settlementModeId">
        <settleSelect v-model:methodId="form.settlementModeId" />
      </el-form-item>
      <el-form-item label="结算说明：" prop="settlementExplain">
        <el-input v-model="form.settlementExplain" class="w390"></el-input>
      </el-form-item>
      <el-form-item label="经办人：" prop="accountingId">
        <select-common placeholder="请选择经办人" v-model:modelValue="form.accountingId" :options="props.userOptions"
          valueKey="id" labelKey="CnName" class="w145 ml-mr-0" />
      </el-form-item>
      <el-form-item label="退款说明：" prop="refundExplain">
        <el-input v-model="form.refundExplain" class="w880"></el-input>
      </el-form-item>
      <el-form-item label="退款金额：">
        <el-input v-model="form.contractRefund" class="w145" disabled></el-input>
      </el-form-item>
      <el-form-item label="实际退款：">
        <el-input v-model="form.actualRefund" class="w145" disabled></el-input>
      </el-form-item>
      <el-form-item label="最终修改人：">
        <el-input class="w145" disabled></el-input>
      </el-form-item>
      <el-form-item label="审核人：" prop="auditUser">
        <el-input v-model="form.auditUser" class="w145" disabled></el-input>
      </el-form-item>
      <el-form-item label="单据状态：">
        <div class="w145">{{ getLabelByValue(form.auditStatus) }}</div>
      </el-form-item>
      <el-form-item label="录入人：">
        <el-input v-model="form.cnName" class="w145" disabled></el-input>
      </el-form-item>
    </el-form>
    <el-card style="width: 100%">
      <template #header>
        <div class="header_top">
          退款明细
          <div>
            <el-button size="small" @click="dialogVisible = true" type="primary">新增</el-button>
          </div>
        </div>
      </template>
      <el-table :data="form.newCrmRefund" style="width: 100%" show-summary border>
        <el-table-column label="操作" width="95">
          <template #default="scope">
            <el-button size="small" link type="danger"
              @click="delServer(form.newCrmRefund, scope.$index)">删除</el-button>
          </template>
        </el-table-column>
        <el-table-column label="退款说明" width="130">
          <template #default="{ row }">
            <el-input v-model="row.refundExplain" class="w130" size="small"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="合同(单据)号" width="130">
          <template #default="{ row }">
            <el-input v-model="row.contractNum" class="w130" size="small" disabled>
              <template #append><el-button icon="Search" @click="dialogVisible = true" size="small" /></template>
            </el-input>
          </template>
        </el-table-column>
        <el-table-column label="应收日期" width="130">
          <template #default="{ row }">
            {{ row.receivableTime }}
          </template>
        </el-table-column>
        <el-table-column label="应收月份" width="250">
          <template #default="{ row }">
            <div v-if="row.paymentStartMonth">
              {{ row.paymentStartMonth }} - {{ row.paymentEndMonth }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="费用类型" width="150">
          <template #default="{ row }">
            {{ row.costType }}
          </template>
        </el-table-column>
        <el-table-column prop="receivedAmount" label="已收" min-width="100">
        </el-table-column>
        <el-table-column prop="thisRefund" label="本次退款" min-width="100">
          <template #default="{ row }">
            <qzf-input v-model:num="row.thisRefund" @changeLx="changeAmount" />
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </el-drawer>
  <el-dialog :close-on-click-modal="false" v-model="dialogVisible" title="选择收款明细" width="600px">
    <el-table :data="contractList" style="width: 100%" show-summary border
      @selection-change="handleSelectContractChange" max-height="400">
      <el-table-column type="selection" width="55" align="center" />

      <el-table-column label="合同(单据)号" prop="contractNum">
      </el-table-column>
      <el-table-column label="收款日期" prop="receivableTime">
      </el-table-column>
      <el-table-column label="费用类型" prop="costType"> </el-table-column>
      <el-table-column label="已收金额" prop="receivedAmount">
      </el-table-column>
    </el-table>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">取消</el-button>
        <el-button size="small" type="primary" @click="selectSure">确定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref, defineExpose, onMounted, getCurrentInstance } from "vue"
import { ElMessage } from "element-plus"
import {
  customerList,
  contractPaymentTypeList,
  saveContractRefundRecord,
  contractRefundList,
  refundInfo,
} from "@/api/newCrm.js"
import { CONTRACT_STATUS } from "@/utils/commonData";
import { getCurrentDate } from "@/utils/fun"
import settleSelect from "./settleSelect.vue"
const { proxy } = getCurrentInstance()
const props = defineProps({
  userOptions: {
    type: Array,
  },
})
const emit = defineEmits()
const drawer = ref(false)
const form = ref({
  newCrmRefund: [],
  auditStatus: 1,
  receivedAmount: 0,
  actualPayment: 0,
})
const paymentTypeOptions = ref([])
const customerOptions = ref([])
const ruleFormRef = ref()
const rules = ref({
  customerId: [{ required: true, message: "请输入客户名称", trigger: "blur" }],
  businessTypeId: [
    { required: true, message: "请选择业务类型", trigger: "change" },
  ],
})
const contractList = ref([])
const contractSels = ref([])
const dialogVisible = ref(false)
onMounted(() => {
  contractPaymentTypeList({}).then((res) => {
    paymentTypeOptions.value = res.data.data.list
  })
})
const init = (item) => {
  getCustomerList()
  reset()
  if (item) {
    refundInfo({ id: item.id }).then((res) => {
      if (res.data.msg == "success") {
        form.value = res.data.data.list
      }
    })
  } else {
    form.value.refundDate = getCurrentDate()
    form.value.cnName = proxy.$store.getters["user/user"].cnName
    form.value.accountingId = proxy.$store.getters["user/user"].userId
  }
  drawer.value = true
}
const getCustomerList = () => {
  customerList({ customerType: "2" }).then((res) => {
    if (res.data.msg == "success") {
      customerOptions.value = res.data.data.list
    }
  })
}
const changeCustomer = () => {
  form.value.newCrmRefund = []
  contractRefundList({
    customerId: form.value.customerId,
  }).then((res) => {
    if (res.data.msg == "success") {
      contractList.value = res.data.data.list
    }
  })
}
const changeAmount = () => {
  form.value.contractRefund = 0
  form.value.actualRefund = 0
  form.value.newCrmRefund?.map((v) => {
    v.thisRefund = v.thisRefund ? v.thisRefund : 0
    form.value.actualRefund += v.thisRefund
    form.value.contractRefund = form.value.actualRefund
  })
}
//删除
const delServer = (list, index) => {
  list.splice(index, 1)
}
//保存
const save = () => {
  ruleFormRef.value.validate((valid) => {
    if (valid) {
      form.value.newCrmRefund = form.value.newCrmRefund.filter((v) => v.id)
      saveContractRefundRecord(form.value).then((res) => {
        if (res.data.msg == "success") {
          ElMessage.success("保存成功")
          drawer.value = false
          emit("success")
        }
      })
    }
  })
}
//勾选
const handleSelectContractChange = (e) => {
  contractSels.value = e
}
//选择
const selectSure = () => {
  if (contractSels.value.length == 0) {
    ElMessage.warning("请选择收款明细")
    return
  }
  let arr = [...form.value.newCrmRefund, ...contractSels.value]
  form.value.newCrmRefund = Object.values(
    arr.reduce((acc, item, index) => {
      item.sort = index
      if (!acc[item.id]) {
        acc[item.id] = item
      }
      return acc
    }, {})
  )
  form.value.newCrmRefund.sort((a, b) => a.sort - b.sort)
  dialogVisible.value = false
  changeAmount()
}
const getLabelByValue = (value) => {
  const status = CONTRACT_STATUS.find((item) => item.value === value);
  return status ? status.label : "未知状态";
};
const reset = () => {
  form.value = {
    newCrmRefund: [],
    auditStatus: 1,
    receivedAmount: 0,
    actualPayment: 0,
  }
}
defineExpose({
  init,
})
</script>

<style scoped lang="scss">
.header_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.w145 {
  width: 145px !important;
}

.w173 {
  width: 173px !important;
}

.w390 {
  width: 390px !important;
}

.w880 {
  width: 880px;
}

.el-form--inline .el-form-item {
  margin-right: 0px;
}

.ml0 {
  margin-left: 0px;
}

.ml-mr-0 {
  margin: 0;
}

:deep(.el-card__header) {
  padding: 10px 20px;
}
</style>
