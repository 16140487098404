<template>
  <div class="app-container">
    <div class="top_select">
      <div>
        <el-button icon="Refresh" plain type="primary" size="small" class="mr-5" @click="getList()"><span style="margin-top: 2px;">刷新</span></el-button>
        <el-input size="small" placeholder="请输入客户编码/客户名称" v-model.trim="listQuery.customerName" style="width: 200px"
          @keyup.enter="getList" clearable></el-input>
        <el-button size="small" type="primary" @click="getList" style="margin-right: 10px" icon="Search">搜索</el-button>
        <search @success="getList" @cancel="cancel">
          <el-form style="width: 550px" label-width="90px" size="small" inline="true" class="style_form">
            <el-form-item label="退款日期：">
              <el-date-picker style="width: 150px" v-model="listQuery.beginTime" type="date" placeholder="起始日期"
                clearable value-format="YYYY-MM-DD" />
              &nbsp;-&nbsp;
              <el-date-picker style="width: 150px" v-model="listQuery.endTime" type="date" placeholder="终止日期" clearable
                value-format="YYYY-MM-DD" />
            </el-form-item>
            <el-form-item label="经办人：">
              <select-common placeholder="请选择经办人" v-model:modelValue="listQuery.accountingId" :options="userOptions" />
            </el-form-item>
            <el-form-item label="审核人：">
              <select-common placeholder="请选择审核人" v-model:modelValue="listQuery.auditUserId" :options="userOptions" />
            </el-form-item>
            <el-form-item label="审核状态：">
              <select-common placeholder="请选择审核状态" v-model:modelValue="listQuery.auditStatus"
                :options="statusOptions" />
            </el-form-item>
            <el-form-item label="单据编码：">
              <el-input v-model="listQuery.documentCode" placeholder="请输入单据编码" clearable></el-input>
            </el-form-item>
            <el-form-item label="结算方式：">
              <select-common placeholder="请选择结算方式" v-model:modelValue="listQuery.settlementModeId"
                :options="paymentOptions" />
            </el-form-item>
          </el-form>
        </search>
      </div>
      <div>
        <el-button size="small" type="primary" icon="Folder" @click="exportExcel">导出</el-button>
        <el-button size="small" type="primary" icon="Plus" @click="createNew">新增</el-button>
      </div>
    </div>
    <div :style="{ height: contentStyleObj, paddingTop: '10px' }">
      <vxe-table v-loading="loading" :data="list" :scroll-y="{ enabled: true, gt: 0, mode: 'wheel' }" height="auto" style="width: 100%"
        border stripe auto-resize size="mini" @checkbox-all="handleSelectionChangeAll"
        @checkbox-change="handleSelectionChange" :column-config="{ resizable: true }">
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <vxe-column align="center" field="sortColumn" type="checkbox" width="50">
        </vxe-column>
        <vxe-column field="refundDate" title="退款日期" width="90"> </vxe-column>
        <vxe-column field="documentCode" title="单据编码" minWidth="90">
          <template #default="scope">
            <span class="clickable_style" @click="refundDetail(scope.row)">{{
              scope.row.documentCode
            }}</span>
          </template>
        </vxe-column>
        <vxe-column field="createdAt" title="操作日期" width="90">
          <template #default="scope">
            <span>{{ $parseTime(scope.row.createdAt, "{y}-{m}-{d}") }}</span>
          </template>
        </vxe-column>
        <vxe-column field="customerNum" title="客户编码" minWidth="90"></vxe-column>
        <vxe-column field="customerName" title="客户名称" minWidth="90"> </vxe-column>
        <vxe-column field="actualRefund" title="金额" minWidth="90"> </vxe-column>
        <vxe-column field="accountingName" title="经办人" minWidth="90"> </vxe-column>
        <vxe-column field="cnName" title="录入人" minWidth="90"> </vxe-column>
        <vxe-column field="refundExplain" title="退款说明" minWidth="130">
        </vxe-column>
        <vxe-column title="状态" minWidth="90">
          <template #default="scope">
            <span>{{ getLabelByValue(scope.row.auditStatus) }}</span>
          </template>
        </vxe-column>
        <vxe-column field="opera" title="操作" width="260">
          <template #default="{ row }">
            <el-button type="primary" link :disabled="row.auditStatus !== 5"
              @click="() => showToExamine(row)">审核</el-button>
            <el-button type="primary" link :disabled="![2, 6].includes(row.auditStatus)"
              @click="() => antiAudit(row)">反审核</el-button>
            <el-button type="primary" link @click="() => approveHistoryShow(row)">审核历史</el-button>
            <el-button type="primary" link @click="() => submitApprove(row)"
              :disabled="row.auditStatus !== 1">提交审核</el-button>
          </template>
        </vxe-column>
      </vxe-table>
    </div>

    <div class="page_container">
      <qzf-pagination
        :total="total"
        v-model:page="listQuery.page"
        v-model:limit="listQuery.limit"
        @pagination="getList()"
      />
    </div>

    <SubmitCheck ref="submitCheckRef" :type="4" @success="getList" />
    <ToExamine ref="toExamineRef" :type="4" @success="getList" />
    <CheckRecord ref="checkRecordRef" :type="4" />
    <refundForm ref="refundFormRef" :userOptions="userOptions" @success="getList" />"
  </div>
</template>

<script setup>
import { ref, getCurrentInstance, onMounted } from "vue";
import { ElMessageBox, ElMessage } from 'element-plus'
import {
  CONTRACT_STATUS,
} from "@/utils/commonData";
import { findPeople } from "@/api/base";
import { refundList, paymentMethodList, refundAntiApprove, exportRefund } from "@/api/newCrm";
import SubmitCheck from "@/views/collectionSystem/contract/components/submitCheck.vue"
import ToExamine from './components/ToExamine.vue'
import CheckRecord from "@/views/collectionSystem/contract/components/checkRecord.vue";
import refundForm from "./components/refundForm.vue";

const { proxy } = getCurrentInstance();
const list = ref([]);
const contentStyleObj = ref({});
const loading = ref(false);
const listQuery = ref({
  page: 1,
  limit: 20,
});
const statusOptions = ref([
  ...[{ label: "全部", value: 0 }],
  ...CONTRACT_STATUS,
]);

const userOptions = ref([])
const paymentOptions = ref([])
const total = ref(0)
const refundFormRef = ref()
const checkRecordRef = ref()
const submitCheckRef = ref()
const toExamineRef = ref()

getList()

onMounted(() => {
  contentStyleObj.value = proxy.$getHeight(210);
  findPeople({ code: "bookkeeping" }).then((res) => {
    userOptions.value = res.data.data.list.map(item => {
      return {
        ...item,
        label: item.CnName,
        value: item.id,
      }
    });
  });
  paymentMethodList().then((res) => {
    paymentOptions.value = res.data?.data?.list?.map(item => {
      return {
        label: item.methodName,
        value: item.id
      }
    });
  });
});

function cancel() {
  listQuery.value = {
    page: 1,
    limit: 20,
  }
  getList()
}

function getList() {
  refundList({
    ...listQuery.value,
    auditUserId: listQuery.value.auditUserId || undefined,
    accountingId: listQuery.value.accountingId || undefined,
    auditStatus: listQuery.value.accountingId || undefined,
    settlementModeId: listQuery.value.settlementModeId || undefined,
  }).then((res) => {
    if (res.data.msg == "success") {
      list.value = res.data.data.list;
      total.value = res.data.data.total
    }
  });
};

const getLabelByValue = (value) => {
  const status = CONTRACT_STATUS.find((item) => item.value === value);
  return status ? status.label : "未知状态";
};

function submitApprove(item) {
  submitCheckRef.value.init(item)
}

function showToExamine(row) {
  toExamineRef.value.init(row)
}

function approveHistoryShow(row) {
  checkRecordRef.value.init(row)
}
const createNew = () => {
  refundFormRef.value.init()
}
const refundDetail = (row) => {
  refundFormRef.value.init(row)
}
function antiAudit(row) {
  ElMessageBox.confirm('确定反审核?', '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning'
  }).then(() => {
    refundAntiApprove({
      type: 4,
      bingingId: [row.id],
      status: 5,
    }).then(res => {
      if (res.data.msg == "success") {
        ElMessage.success("删除成功")
        getList()
      }
    })
  })
}

function exportExcel() {
  exportPayment({ids: ids.value, query: {...listQuery.value}})
    .then(res => {
      if (res.data.msg == "success") {
        window.open(res.data.data.url, '_blank');
      }
    })
}
</script>
<script>
export default {
  name: "refundSign",
};
</script>
<style scoped lang="scss">
.page_container{
  float: right;
  margin-top: 10px;
}
.top_select {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

:deep(.el-checkbox) {
  margin-right: 15px;
}

.style_form {
  .el-input {
    width: 147px;
  }

  .el-select {
    width: 147px;
    margin: 0;
  }
}

.filter-item {
  margin-right: 15px !important;
}

:deep(.el-dropdown-menu__item) {
  display: block;
}
</style>
